.w-280 {
  width: 280px;
}

.statistics {
  border-radius: 15px;
  margin-bottom: 10px;
  margin-top: 99px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.statistics .counter-text > * {
  line-height: 40px;
  font-size: 22px;
  font-weight: 700;
  color: var(--golden-color);
}
.statistics .counter-title {
  text-transform: uppercase;
  color: var(--golden-color);
  font-size: 10px;
}

.total-plays {
  border-right: 1px solid var(--border-light);
}

.heroes {
  background: var(--secondary-lighter);
  padding: 10px 20px 10px 20px;
}
.game-left-column .row {
  margin: 0;
}
.game-left-column .tab-content {
  padding: 0;
}
.container-page {
  margin-top: 120px;
  min-height: 70vh;
}
.container-page.tower {
  margin-top: 180px;
}

.player-alias {
  max-width: 180px;
  overflow: hidden;
}

@media (max-width: 992px) {
  .container-page.tower {
    margin-top: 160px;
  }
  #root {
    overflow-x: hidden;
  }
  .container-page {
    margin-top: 20px;
  }
  .container-page {
    margin-top: 100px;
  }
}
@media screen and (min-width: 800px) and (max-width: 1280px) {
  .col-md-12 {
    margin-top: 20px !important;
  }
  .container-page.tower {
    margin-top: 200px;
  }
}
