.message {
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  );
}
@keyframes fadeInFast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the animation to elements with the 'fade-in' class */
.fade-in-fast {
  animation: fadeInFast 0.3s ease-in-out;
}
.message .username {
  min-width: 100%;
}
.message p {
  margin-bottom: 0px;
}
.message .content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  padding-left: 30px;
}
