.coin-selection {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
}

.coin-icon {
  width: 32px;
  height: 32px;
  margin-right: auto;
  margin-left: auto;
}
.crypto-input {
  max-width: 350px;
}
