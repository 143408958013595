.balance-ic {
  height: 27px;
}
.balance-wrapper {
  max-width: 70%;
}
.betting-bar .btn {
  padding: 0px;
}
.betting-bar > button > span {
  font-size: 13.5px;
}
@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  20% {
    transform: rotate(
      500deg
    ); /* Increase the initial rotation for a faster start */
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(720deg);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: rotate(1080deg);
    animation-timing-function: ease-in;
  }
}

@keyframes spinRed {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1066.58125deg);
  }
}

@keyframes spinBlack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1091.58125deg);
  }
}

@keyframes spinGreen {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1072.58125deg);
  }
}

@keyframes spinYellow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1079.08125deg);
  }
}

.spin {
  animation: spinAnimation 7s infinite;
}
.spin-red {
  animation: spinRed 5s cubic-bezier(0, 0, 0.28, 1) forwards;
}
.spin-black {
  animation: spinBlack 5s cubic-bezier(0, 0, 0.28, 1) forwards;
}
.spin-green {
  animation: spinGreen 5s cubic-bezier(0, 0, 0.28, 1) forwards;
}

.spin-yellow {
  animation: spinYellow 5s cubic-bezier(0, 0, 0.28, 1) forwards;
}

/* TODO: Considering unifying different backend colros with JS instead of having it messed up like this */

.roll-stat.red {
  border: 1px solid var(--primary);

  color: var(--primary);
}

.roll-stat.red {
  border: 1px solid var(--primary);

  color: var(--primary);
}

.roll-stat.black {
  border: 1px solid var(--orange);

  color: var(--orange);
}
.roll-stat.green {
  border: 1px solid var(--green);

  color: var(--green);
}
.roll-stat.yellow {
  border: 1px solid var(--purple);

  color: var(--purple);
}
.roll-stat {
  border-radius: 10px;
  font-weight: bold;
  width: 45px;
  height: 45px;
  text-align: center;
}
.roll-bar {
  width: 100%;
  height: 5px;
  border-radius: 20px;
}
.roll-bar.red {
  width: 60%;
  background: var(--primary);
}
.roll-bar.black {
  width: 65%;
  background: var(--orange);
}
.roll-bar.green {
  width: 70%;
  background: var(--green);
}
.roll-bar.yellow {
  width: 80%;
  background: var(--purple);
}
.hash-div {
  height: 40px;
}

.roll-mid {
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  min-height: 450px;
  margin-bottom: 30px;
}
.roll-mid img {
  max-width: 100%;
}
.roll-monkey {
  z-index: 5;
  position: relative;
  left: 10%;
}
.monkey-root {
  height: 400px;
  width: 382px;
}
.roll-arrow {
  font-size: 24px;
  color: #50446c;
  font-weight: bold;
  position: absolute;
  z-index: 7;
  left: 50.5%;
  transform: translateX(-50%);
  bottom: 10%;
  text-align: center;
  line-height: 26px;
  padding-bottom: 11px;
}

.roll-table {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  z-index: 2;
}

.roll-colors {
  position: absolute;

  top: 32px;
  left: 0;
  right: 0;
  z-index: 3;
}
#roll-text {
  color: #50446c !important;
  text-transform: capitalize;
}
.roll-arrow span {
  top: 110%;
  left: 50%;
  content: " ";
  position: absolute;
  pointer-events: none;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 7.5px 0 7.5px;
  border-color: #50446c transparent transparent transparent;
}

@media (max-width: 992px) {
  .roll-arrow {
    bottom: 12%;
  }
  .roll-monkey {
    left: 11%;
  }
  .hash-text {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .balance-wrapper {
    max-width: 100%;
  }
}
