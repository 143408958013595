thead > tr > th {
  background-color: var(--secondary-light);
  text-transform: uppercase;
}
.table > :not(caption) > * > * {
  color: var(--lightgray) !important;
  font-weight: initial;
}

.table thead th {
  background-color: var(--secondary-light) !important ;
}

.difficulty-icon {
  max-height: 24px;
  max-width: 24px;
}

.table.table-default {
  border-collapse: separate;
  border-spacing: 0 0px;
  width: 100%;
  margin-bottom: 0px;
  border-end-end-radius: 50px;
  font-size: 13px;
}

.table.table-default tbody tr {
  background-color: unset !important;
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  ) !important;

  --bs-table-bg: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  ) !important;

  border-radius: 15px !important;
}

.table tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.table tr td {
  vertical-align: middle;
}
.table tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.difficulty-wrapper {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
}


@media (max-width: 768px) {
  .table-vertical .table-default td {
    display: block; /* Make table cells block-level on mobile */
    width: 100%;
    text-align: left;
  }
  
  .table-vertical .table-default td:before {
    content: attr(data-label); /* Add a label before the value */
    font-weight: bold;
    display: block;
  }
  
  .table-vertical .table-default th {
    display: none; /* Hide headers on mobile */
  }

  .table-vertical .table-default td {
    border-top: 1px solid #ccc;
    padding: 10px;
  }
}
