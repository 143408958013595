.navbar button.sound-button {
  background: 0 0;
  box-shadow: none;
  padding-left: 5px;
  width: 55px;
  height: 56px;
  font-size: 1.3rem;
  border: none;
  color: var(--golden-color);
}

.navbar .sound.icon-sound-off {
  color: var(--lightgray);
}

button.sound-button:hover {
  background-color: inherit;
}

.table-vertical th {
  text-align: left;
}
.table-vertical td {
  text-align: center;
}
