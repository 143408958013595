.progress-bar-div .bg-primary {
  background-color: var(--primary) !important;
}
.progress-bar-div .progress {
  background-color: var(--secondary-lighter);
  height: 5px;
}

.progress-bar-div {
  overflow: visible;
}

.progress-bar-div .icon-img {
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -24px;
  z-index: 999999;
  position: relative;
}
