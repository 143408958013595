nav.navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

nav button {
  text-transform: uppercase;
}

.container {
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.navbar-collapse {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  background-color: none;
}

nav .navbar-expand {
  flex: auto;
  justify-content: center;
}

.navbar-expand-lg .navbar-toggler {
  display: none;
}

.toggle-navigation {
  color: var(--golden-color);
}

.bg-dark-color {
  background-color: rgba(49, 56, 111, 0.6);
  transition: all 0.3s ease-in-out;
}

.bg-dark {
  background-image: linear-gradient(#31386f, rgba(49, 56, 111, 0.5));
  background-color: rgba(49, 56, 111, 0.5);
}

nav .navbar-brand {
  margin-right: 0;
}

.welcome-text {
  font-size: 26px;
  font-weight: 300;
  color: white;
}

.menu .header-icos i {
  color: white;
}

button.login-button {
  background-image: linear-gradient(#bd807d, #e5b794);
  border-radius: 15px;
  padding: 10px;
  border: none;
}

.bottom-text {
  color: white;
}

@media (max-width: 992px) {
  .sm-flex-basis-auto {
    flex-basis: auto;
  }
  .menu {
    margin-left: auto;
  }
  /* When the screen width is less than 992px, apply the sliding effect */
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    overflow-y: auto;
    background-image: linear-gradient(#31386f, #714f72);
  }
  .navbar-expand-lg .navbar-toggler {
    display: block;
    margin-left: auto;
    /* Show the toggler on smaller screens */
  }
  .navbar-expand-lg .navbar-collapse.show {
    transform: translateX(0);
  }
  nav .navbar-toggler {
    border: none;
  }
  .logo-image {
    display: none;
  }
  nav .navbar-toggler:focus {
    box-shadow: none;
  }
}
