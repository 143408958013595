form.form-verify .col > div{
    text-align: left;
    color: white;
}
form.form-verify button{
    height: 50px;
}
@media (max-width: 992px) {
    form.form-verify .col-12 {
       margin-top: 10px;
    } 
}
