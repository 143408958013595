.skin-item-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  height: 55px;
}

.skin-image {
  height: 100px;
  width: auto;
}
