.games-container .card {
  min-height: 400px;
  width: 250px;
  border-radius: 20px;
  transition:
    transform 0.3s ease,
    border 0.3s ease;
}

.games-container .card.active {
  border: 4px solid var(--golden-color);
}

.games-container .card:hover {
  transform: scale(1.05); /* Adjust the scale factor as needed */
  border: 4px solid var(--golden-color);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.4);
}
.games-container .card {
  background: transparent;
}
