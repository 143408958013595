.chat-field input {
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  );
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.chat-div button.chat-btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
#chat-field::placeholder {
  color: var(--lightgray);
}
