.bet-input {
  outline: none;
  max-width: 100px;
  font-size: 16px;
}

.ticket-wrapper {
  position: relative;
  z-index: 80;
  background-image: linear-gradient(#bd807d, #e5b794);
  border-radius: 15px;
  margin-top: 0px;
  height: 100%;
  width: 100%;
  transition: width 0.5s ease;
}
/* .ticket-wrapper.admin {
  background-image: none;
} */

.ticket-wrapper .col,
.balance-unit {
  background-image: url("../../../../public/images/button-pattern.png");
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 2px;
  position: relative;
  z-index: 101;
  background-color: var(--golden-color);
  color: var(--golden-dark);
  font-size: 24px;
  font-weight: 700;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  height: 34px;
  background-position: center center;
}

.betting-tiles button.btn:hover {
  box-shadow:
    inset 0 0 0 1px #c0847f,
    rgba(0, 0, 0, 0.05) 0 2px !important;
  background-color: #eec194 !important;
}
.betting-tiles .game-ic {
  max-height: 20px;
}
.balance-unit > * {
  color: var(--golden-dark);
  font-size: 24px;
  font-weight: 700;
}

.ticket-wrapper .balance-unit {
  background-image: url("../../../../public/images/currency-pattern.svg");
  background-size: cover;
}

.ticket-wrapper .col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-wrapper .col .amount {
  text-shadow: none;
  color: #cb9385;
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;
  text-transform: none;
  border: 1px solid #e5b793;
  background-color: #edc197;
  padding: 7px;
  border-radius: 15px;
}

.action-wrapper button {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  background: var(--secondary-base) !important;
}

.action-wrapper .button-wrapper {
  width: 80px;
}

.action-wrapper .button-wrapper button.btn {
  border-radius: 10px;
  color: var(--golden-color);
}

.difficulty-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.difficulty-button img {
  height: 20px;
}

.accordion .card {
  border-radius: 0px;
}

.accordion button.btn {
  box-shadow: none;
}

.verify-button svg path {
  color: white;
}

.monkey-head-wrapper {
  position: absolute;
  max-width: 80%;
  z-index: 90;
  top: -11%;
  left: 10%;
}

.monkey-head-wrapper-second {
  position: relative;
  left: 27.9%;
  max-width: 100%;
  z-index: 99;
  transform: translate(25%, -80%);
}

.ticket-wrapper.medium {
  width: 350px;
}
.monkey-div {
  width: 350px;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.monkey-body {
  position: relative;
  transform: translate(-7.5%, -42%);
  z-index: 0;
}

.monkey-wrapper {
  position: absolute;
  max-width: 350px;
}

.monkey-left {
  position: absolute;
  top: 55%;
  left: -7%;
  z-index: 0;
  max-height: 70px;
}

.monkey-right {
  position: absolute;
  top: 55%;
  right: -7%;
  z-index: 0;
  max-height: 70px;
}

a.green-link,
.modal-content a {
  all: unset !important;
  color: var(--lightgray) !important;
  cursor: pointer !important;
}

a.green-link:hover,
.modal-content a:hover,
.modal-content a:hover > * {
  color: var(--win-color) !important;
  text-decoration: underline !important;
  transition: all 0.3s !important;
}

.betting-tiles {
  height: 505px;
  /* TODO: CONSIDER MATCHING OLD APP HEIGHT OF THIS ELEMENT: 385PX */
}

.betting-tiles .game__icon img {
  max-height: 24px;
}

.betting-tiles button {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.betting-tiles .row {
  flex: 1;
}

.betting-tiles .row .col {
  height: auto;
  padding: 0;
}

.betting-tiles .fa-icon {
  position: absolute;
  height: 14px;
  top: 40%;
  color: var(--golden-color) !important;
  z-index: 200;
  width: auto;
}

.fa-icon path,
.icon-primary path {
  fill: var(--golden-color);
}
.icon-primary bomb {
  fill: var(--secondary-color);
}
.fa-left {
  left: -45px;
}
.fa-right {
  right: -45px;
}

.game-text {
  color: var(--brown);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: -0.2px;
}
.game__icon svg path {
  fill: var(--brown);
}
.betting-tiles .win-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.betting-tiles .win-div img {
  height: 24px;
  vertical-align: middle;
}

.demo-overlay {
  background: rgb(64, 57, 93);
  background: linear-gradient(
    0deg,
    rgba(64, 57, 93, 0.8855917366946778) 0%,
    rgba(121, 43, 194, 0.13) 100%
  );
  height: 100%;
  width: 100%;
  z-index: 9999;
  position: absolute;
  border-radius: 15px;
  overflow: hidden;
}
.demo-label {
  width: 200px;
  right: -15%;

  z-index: 999;
  text-align: center;
  top: 30px;
  transform: rotate(40deg);
}
.demo-label-wrapper {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
@media (max-width: 992px) {
  .demo-label {
    width: 200px;
    right: -15%;
    top: 10px;
    transform: rotate(35deg);
  }
}
