.admin-table-wrap {
  overflow-x: auto;
}
.admin-panel {
  background: var(--admin-overlay);
  padding: 20px;
}
.rdrDateRangePickerWrapper > * {
  background: var(--secondary-lighter) !important;
}
.rdrDateRangePickerWrapper > .rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrDateDisplayWrapper,
.rdrStaticRange,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: var(--secondary-lighter) !important;
}
.rdrDays,
.rdrMonthAndYearWrapper,
.rdrWeekDays,
.rdrDateRangeWrapper {
  background: white !important;
}
.rdrEndEdge,
.rdrStartEdge,
.rdrInRange {
  color: var(--primary) !important;
}
.rdrDateDisplayItemActive,
.rdrDateInput.rdrDateDisplayItem {
  background: var(--secondary-base) !important;
  color: white !important;
}
.rdrDateDisplayItemActive input,
.rdrDateInput.rdrDateDisplayItem input {
  color: white !important;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: var(--purple-text) !important;
}
.rdrInputRangeInput,
.rdrNextPrevButton {
  background: var(--primary) !important;
  color: var(--purple-text) !important;
  border: none !important;
}
.rdrDateDisplayItemActive {
  border-color: var(--primary) !important;
}
.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: var(--primary) !important;
  color: var(--purple-text) !important;
  border-color: var(--primary) !important;

  transition: all 0.3s;
}
.rdrStaticRange,
.rdrDefinedRangesWrapper {
  border-color: var(--primary) !important;
}
.rdrMonthAndYearWrapper,
.rdrMonthAndYearPickers select {
  background: var(--secondary-light) !important;
  color: white !important;
}
.rdrMonth {
  background: var(--secondary-base);
}
.rdrWeekDays {
  background: var(--secondary-base) !important;
  color: rgb(228, 226, 226) !important;
}
.rdrWeekDay {
  color: rgb(228, 226, 226) !important;
}
.rdrDays {
  background-color: var(--secondary-light) !important;
  border-radius: 5%;
}
.rdrDayNumber span {
  color: white !important;
}
.admin-towers .container-page{
margin-top: 20px;
}
@media (max-width: 992px) {
  .rdrDateRangePickerWrapper {
    display: flex !important;
    width: 100%;
    justify-content: center;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
}
