.footer-text{
    color: white;
    font-size: 13px;
}
footer{
    position: relative;
    background-color: var(--secondary-lighter);
    overflow-x: hidden;
}
.giftcard-bg{
   z-index:  0;
    max-height: 300px;
    position: absolute;
    top: -50px;
}
footer h2{
    display: block;
    margin: 0 auto;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 200;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 1.5rem;
}
.footer .footer-middle-row a {
    color: #fff;
    line-height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    transition: opacity ease .3s;
    text-decoration: none;
}
 .footer-middle-row a:hover, .footer .footer-top-row .links ul li a:hover {
    opacity: .7;
    text-decoration: none;
}
.footer .bottom-row{
    background: var(--purple-dark);
}
.plus18{
    font-weight: 700;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-size: 16px;
}