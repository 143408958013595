:root {
  --primary: #f5cc9c;
  --secondary-color: #40395d;
  --golden-color: #f5cc9c;
  --secondary-light: #493e65;
  --secondary-lighter: #50446d;
  --toast-bg: #50446dea;
  --secondary-disabled: #554c68;
  --secondary-base: #625178;
  --admin-overlay: #625178a8;
  --lightgray: #c7bfd5;
  --border-light: #625178;
  --golden-light: #cb9385;
  --golden-dark: #8f6b7b;
  --purple-shade: #574871;
  --purple-text: #5c4c74;
  --purple-dark: #403659;
  --win-color: #c6e47f;
  --orange: #f68e56;
  --green: #59e09d;
  --purple: #c7bfd5;
  --brown: rgb(75, 35, 35);
  --bs-body-bg: transparent !important;
  --bs-primary-rgb: #f5cc9c !important;
  --bs-progress-bg: #50446d !important
;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

/* Apply Lato font to your elements */
body {
  font-family: "Lato", sans-serif !important;
}
* {
  color: var(--lightgray);
}

.nav-link.disabled,
.nav-link:disabled {
  color: var(--lightgray) !important;
}
.fade.modal-backdrop {
  height: 100%;
}
.bg-green {
  background: var(--green);
}
body,
main {
  background: var(--secondary-lighter) !important;
}
.text-secondary {
  color: var(--secondary);
}
.Toastify__toast-container {
  top: -5px !important;
  right: 0 !important;
  width: 100vw !important;
}
.Toastify__toast-theme--dark {
  background: var(--toast-bg) !important;
  min-height: 50px !important;
}
.Toastify__toast-body {
  padding-left: 20px !important;
}
.icon-image {
  max-height: 24px;
}
.icon-image-lg {
  max-height: 32px;
}
.Toastify__toast-body > * {
  color: white;
}
.Toastify__close-button {
  margin-right: 5px;
}
.bg-primary,
div.bg-primary,
span.bg-primary,
a.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}
.text-left {
  text-align: left;
}
.no-gutters > .col {
  padding: 0;
}
.bg-purple-shade {
  background: var(--purple-shade);
}
button.border-primary,
div.border-primary {
  border-color: var(--primary) !important;
}
input.form-control {
  background-color: var(--secondary-lighter);
  height: 50px;
  border-radius: 10px;
  border: none;
  color: var(--lightgray);
  word-wrap: break-word;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 2px;
}
input.form-control.radius-left {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

input.form-control:focus {
  color: var(--lightgray);
}
.form-label {
  color: white;
  font-size: 14px;
}
.form-verify h4 {
  font-size: 18px;
}
.text-lg {
  font-size: 24px;
}
.text-sm {
  font-size: 10px;
}
input.form-control:focus {
  background-color: var(--secondary-lighter);
  border: none;
  outline: none;
  box-shadow: none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: transparent !important;
}
div.bg-secondary,
button.bg-secondary,
span.bg-secondary,
ul.bg-secondary,
.bg-secondary {
  background-color: var(--secondary-color) !important;
}
.border-transparent {
  border-color: transparent !important;
}
div.bg-secondary-base,
button.bg-secondary-base,
ul.bg-secondary-base,
.bg-secondary-base {
  background-color: var(--secondary-base) !important;
}

div.bg-secondary-lighter {
  background-color: var(--secondary-lighter) !important;
}
button.btn {
  border: 0;
  border-radius: 10px;
  text-shadow: rgba(0, 0, 0, 0.1) 0 1px;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0 2px;
  transition: all 0.3s;
}
button.btn-outline-success {
  border: 2px solid var(--green) !important;
  background: #74a37460;
}
button.btn-outline-success:hover {
  border: 2px solid var(--green) !important;
  background-image: linear-gradient(#54ed9f, #a67ab2);
}
button.btn-outline-danger {
  border: 2px solid #7c282f77 !important;
  background: #92243657;
}
button.btn-outline-danger:hover {
  border: 2px solid #642227 !important;
  background: #dc3544;
}
/* button.border-transparent {
  border-color: transparent !important;
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
/* For modern browsers (including Firefox and Chrome) */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* For Safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--golden-light);
  border-radius: 10px;
  border: 2px solid var(--golden-light);
}

::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

.full-tabs > .nav-item {
  flex: 1;
  text-align: center;
}

.full-tabs > .nav-item > * {
  width: 100%;
}

ul.full-tabs.text-uppercase button {
  text-transform: uppercase;
}

button.btn-secondary:hover {
  opacity: 0.9;
  text-shadow: 0 0 3px rgba(92, 76, 116, 0.5);
}

button.btn-primary {
  color: var(--purple-text) !important;
  background-image: linear-gradient(#f7d9a3, #fdc481);
}
button.btn-primary > span > .bi {
  color: var(--purple-text);
}
.alert.show {
  border: 0px transparent;
}
.alert.alert-secondary {
  background: var(--secondary-base);
  border: 1px solid var(--secondary-light);
}
button.btn-primary:hover {
  color: var(--purple-text);
}

div.bg-secondary-light,
button.bg-secondary-light,
ul.bg-secondary-light,
.bg-secondary-light {
  background-color: var(--secondary-light) !important;
}
.accordion-button:not(.collapsed) {
  all: unset !important;
}

a {
  text-decoration: none !important;
}
.text-ligtgray,
input::placeholder {
  color: var(--lightgray) !important;
}
.relative {
  position: relative;
}
strong {
  color: inherit;
}
.purple-gradient {
  background: linear-gradient(147deg, #31386f, #714f72);
}
.purple-gradient-opacity-50 {
  background: linear-gradient(
    147deg,
    rgba(49, 56, 111, 0.5),
    rgba(113, 79, 114, 0.5)
  );
}

.list-highlight > * {
  color: white;
}
.list-highlight strong {
  color: var(--golden-color);
}
.inner-page-container {
  min-height: 50vh;
  margin-top: 80px;
}
/* .inner-page-container .row > * {
  width: auto;
} */
.rounded-md {
  border-radius: 15px;
}
.rounded-full {
  border-radius: 50%;
}
.rounded-md-bottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.rounded-top-0 {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.rounded-top-md {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.text-golden {
  color: var(--golden-color);
}
.text-win {
  color: var(--win-color);
}
.bg-inactive {
  color: var(--lightgray);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  );
}
.bg-glass {
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  );
}
button.btn.bg-transparent {
  color: inherit;
  background: transparent !important;
  box-shadow: none;
}
.bg-transparent {
  background: transparent !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--golden-color) !important;
  background-color: var(--secondary-light) !important;
  border-color: transparent !important;
}
.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--golden-color) !important;
}
.form-check-input:focus {
  box-shadow: none !important;
}
ul .nav-tabs,
ul.nav-tabs {
  border-color: transparent;
}
ul.nav-tabs.active-green {
  flex-wrap: nowrap;
}
ul.nav-tabs.active-green .nav-link {
  opacity: 0.5;
  transition: all 0.3s;
  border-bottom: 3px solid transparent !important;
}

ul.nav-tabs.active-green .nav-item:hover {
  opacity: 1;
  transition: all 0.3s;
}

ul.nav-tabs.active-green .nav-link:hover {
  opacity: 1;
  transition: all 0.3s;
}

ul.nav-tabs.active-green .nav-link.active {
  border-bottom: 3px solid #59e09d !important;
  opacity: 1;
  transition: all 0.3s;
}

ul.nav-tabs.active-green .nav-link.active span {
  color: white;
}

ul.nav-tabs.active-gold .nav-link.active {
  border-bottom: 4px solid var(--golden-color) !important;
}

ul.nav-tabs.active-gold .nav-link.active > span {
  color: white;
}

.active-gold.border-b {
  border-bottom: 2px solid var(--secondary-lighter);
}

.border-b-primary {
  border-bottom: 2px solid var(--golden-color) !important;
}

.nav-link.active > span {
  color: var(--golden-color);
}

.btn.btn-secondary {
  background-image: linear-gradient(#714f72, #453f70);
  color: var(--lightgray);
}
.btn-secondary > i {
  color: var(--golden-color);
}

ul li.nav-item:hover > *,
ul li.nav-item:focus {
  border-color: transparent !important;
}

ul.nav-tabs {
  background-color: var(--secondary-color);
}
ul .nav-link,
ul .nav-item {
  color: var(--lightgray);
}

ul .nav-link:not(.active):hover {
  color: var(--lightgray);
  transition: all 0.3s;
  opacity: 0.7;
}

ul.active-gold .nav-item:hover > * {
  color: white;
  opacity: 1;
}

button.btn.btn-no-shadow {
  box-shadow: none;
}

.no-box-shadow {
  box-shadow: none;
}

.scene {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  background:
    linear-gradient(0deg, rgba(80, 68, 109, 0.95), rgba(113, 79, 114, 0.65)),
    url("../public/images/background.jpg") center/cover no-repeat;
}

button.btn-success,
button.success {
  background-image: linear-gradient(#54ed9f, #a67ab2);
  opacity: 1;
  text-shadow: none;
  color: white;
}

.success.active,
.success.focus,
.success:active,
.success:focus,
.success:hover,
.open > .success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

button.btn:hover {
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  opacity: 0.9;
  transition: all 0.3s;
}
.bg-gradient,
.card.bg-gradient,
button.bg-gradient {
  background-color: linear-gradient(#714f72, #453f70) !important;
  background-image: linear-gradient(#714f72, #453f70) !important;
  background: linear-gradient(#714f72, #453f70) !important;
}

.ml-auto {
  margin-left: auto;
}
.dropdown dropdown-default.dropdown-toggle,
#dropdown-language,
.dropdown-menu.show.dropdown-menu-secondary {
  background: var(--secondary-base);
  color: var(--lightgray);
}
.dropdown-menu.show .dropdown-item {
  color: var(--lightgray);
}
.dropdown-menu.show .dropdown-item:focus,
.dropdown-menu-secondary.dropdown-menu.show .dropdown-item:hover {
  color: var(--golden-color);
  background: var(--secondary-base);
}
.bg-gradient-purple {
  background: linear-gradient(#714f72, #453f70);
}
.text-xs {
  font-size: 13px;
}
.bg-win,
div.bg-win {
  background-color: #f5cc9c1f;
}
.crypto-address {
  width: 512px;
}
@media (max-width: 992px) {
  .d-sm-none{
    display: none;
  }
  .crypto-address {
    max-width: 250px;
  }
  .sm-justify-content-center {
    justify-content: center;
  }
  .sm-opacity-0 {
    opacity: 0;
  }
  .sm-text-center {
    text-align: center;
  }
  .sm-text-end {
    text-align: end !important;
  }
  .page-container {
    overflow-x: hidden;
  }
}
@media (min-width: 1200px) {
  div.container {
    max-width: 1220px;
  }
}
@media (min-width: 993px) {
  .md-text-center {
    text-align: center !important;
  }
  .lg-gap-3.number-input-div {
    gap: 1rem !important;
  }
  .lg-px-4.filter-wrapper {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.bounce-in {
  animation: bounce-in 0.4s ease-in-out;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounceRight {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

@keyframes bounceLeft {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

.bounce-right {
  animation: bounceRight 1.5s infinite;
}

.bounce-left {
  animation: bounceLeft 1.5s infinite;
}

hr {
  border: 0;
  background-image: url("../public/images/hr.png");
  background-image: -webkit-image-set(url("../public/images/hr.png") 1x);
  background-repeat: no-repeat;
  background-position: center center;
  border-top: none !important;
  height: 1rem;
  width: 100px;
  box-sizing: content-box;
  margin-top: 1rem;
  margin-left: auto !important;
  margin-right: auto !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.flex-1 {
  flex: 1;
}
.text-justify {
  text-align: justify;
}
button:disabled {
  cursor: not-allowed !important;
}
input.form-control:disabled {
  background-color: var(--secondary-disabled);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the animation to elements with the 'fade-in' class */
.fade-in {
  animation: fadeIn 0.7s ease-in-out;
}
.fade-in-fast {
  animation: fadeIn 0.3s ease-in-out;
}
.profile-pic {
  max-height: 200px;
  border-radius: 20px;
  width: auto;
  max-width: 100%;
}
@keyframes fadeInSlideIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-element {
  opacity: 0;
  transform: translateX(-50px);
  animation: fadeInSlideIn 0.7s ease forwards;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.username {
  max-width: 100px;
}

.bg-glass {
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1)
  );
}
.text-red {
  color: rgb(247, 111, 111) !important;
}
.text-green {
  color: #54ed9f !important;
}
.strong-white > strong {
  color: white;
}
.absolute {
  position: absolute;
}
.transition-all {
  transition: all 0.3s;
}
.h-screen {
  height: 100vh;
}
.max-w-sm{
  max-width: 320px;
}