.pagination-div .page-link {
  font-size: 16px;
  border: none;
  padding: 0;
}

.pagination-div .page-link:hover {
  background-color: inherit;
}
.pagination-div ul {
  margin-top: 2px;
}
.active > .page-link,
.page-link.active,
.pagination-div > .active > .page-link,
.page-link.active {
  background-color: var(--primary) !important;
  color: var(--purple-text) !important;
  padding: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: none;
}

.disabled > .page-link,
.page-link.active,
.pagination-div > .active > .page-link,
.page-link.active {
  background-color: transparent !important;
  color: var(--purple-text) !important;
  padding: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: none;
  font-size: 16px;
}
