.alert.cookiebar {
  max-width: 500px;
  margin-right: auto;
  margin-left: 40px;
}
@media (max-width: 992px) {
  .alert.cookiebar {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
