.chat-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--purple);
  border-radius: 10px;
  border: 2px solid var(--purple);
}

.chat-wrapper::-webkit-scrollbar-track {
  background: var(--secondary-base);
}
.chat-wrapper::-webkit-scrollbar {
  width: 5px;
}
.chat-wrapper {
  padding-right: 20px;
  padding-top: 10px;
  margin-top: 10px;
  height: 565px;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-wrapper.sm {
  height: 430px;
}
