.modal-dialog .modal-content {
  background: var(--secondary-base);
}
.modal-dialog .modal-header {
  border-bottom: none;
  position: relative;
  display: flex;
  flex-direction: column;
}
.modal-dialog .modal-body {
  padding-top: 0px;
}

.modal-body {
  height: 75vh;
  overflow-y: auto;
}

.modal-dialog .modal-footer {
  border-top: none;
  background: var(--secondary-light);
}
.modal-dialog hr {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}
.modal-dialog .modal-header .btn.close {
  position: absolute;
  right: 9px;
  top: 9px;
}
.modal-dialog {
  min-width: 60vw;
}

.nav-tabs.bg-transparent .nav-link.active {
  background: transparent !important;
}
.monkey-referal-div {
  text-align: center;
}
.monkey-referal-div img {
  height: 130px;
  width: auto;
  margin-top: auto;
}
.level_text_block {
  border-radius: 4px;
  width: 155px;
  border: 2px solid #836688;
  background: #4a2c50;
  position: relative;
  margin: auto;
  height: 40px;
  transition: all 0.2s ease;
}

.level_text_block p {
  font-weight: bolder;
  color: #fff;
  text-align: center;
  margin: 0;
  line-height: 34px;
}

.level_text_block span {
  color: #4a2c50;
  font-size: 10px;
  background: #836688;
  border-radius: 4px;
  height: 20px;
  line-height: 20px;
  width: 62px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
}
.skins-wrapper {
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.ref-icon {
  background: var(--purple-shade);
  padding: 20px;
}

.page-item {
  display: flex;
  align-items: center;
}
.number-input-div input {
  max-width: 80px;
}
iframe {
  width: 100%;
  height: 70vh;
}
.auto-modal .modal-body {
  height: auto;
  padding-bottom: 50px;
}
@media (max-width: 993px) {
  .skins-wrapper {
    max-height: 31vh;
  }
  .pagination-div ul {
    margin-bottom: 0px;
  }
}
