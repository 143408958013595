.bet-area button.primary {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.bet-area button.orange {
  color: var(--orange);
  border: 1px solid var(--orange);
}

.bet-area button.green {
  color: var(--green);
  border: 1px solid var(--green);
}

.bet-area button.purple {
  color: var(--purple);
  border: 1px solid var(--purple);
}

.bet-area.primary .bet-area-icon path {
  fill: var(--primary);
}
.bet-area.primary span {
  color: var(--primary);
}

.bet-area.orange .bet-area-icon path {
  fill: var(--orange);
}
.bet-area.orange span {
  color: var(--orange);
}

.bet-area.green .bet-area-icon path {
  fill: var(--green);
}
.bet-area.green span {
  color: var(--green);
}

.bet-area.purple .bet-area-icon path {
  fill: var(--purple);
}
.bet-area.purple span {
  color: var(--purple);
}
