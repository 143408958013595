input.form-control {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
input.light-input,
input.light-input:focus {
  background: rgba(255, 255, 255, 0.05);
}
.custom-input button {
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}
input.search-skins {
  width: 100%;
}
input.min,
input.max {
  max-width: 90px;
}
@media (max-width: 992px) {
  input.min,
  input.max {
    max-width: 120px;
  }
}
