.menu .card {
  background-image: linear-gradient(#bd807d, #e5b794);
  border-radius: 15px;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px;
  width: 20rem;
}

.menu .card-body {
  padding: unset;
}

.card-body button {
  display: inline-block;
  margin-left: 1%;
  border: none;
  height: 43px;
  width: 100%;
  text-shadow: rgba(0, 0, 0, 0.2) 0 1px;
}

.header-button-quarter .tooltip-button {
  width: auto;
  height: auto;
  min-width: 100px;
  border: 2px solid #f4e8ed;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  background-color: #63466a;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.header-button-quarter {
  width: 24%;
  display: inline-block;
  margin-left: 1%;
}

@media (max-width: 992px) {
  .menu .card {
    width: 15rem;
  }
}
