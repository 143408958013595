.fullscreen-modal .modal-content {
  background: transparent !important;
  border-color: transparent;
  height: 100%;
}
.fullscreen-modal .modal-dialog {
  height: 100vh;
  margin: 0;
  max-width: 100%;
}

@media (max-width: 992px) {
  .fullscreen-modal .modal-content {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .fullscreen-modal .modal-dialog {
    height: auto;
    margin: 0;
    max-width: 100%;
  }
}
