.spin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.spin-bar {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: auto;
  padding: 20px 0;
  position: relative;
}
.spin-modal-body {
  overflow-x: hidden;
}
.spin-item {
  flex: 0 0 auto;

  text-align: center;
}

.bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  border-radius: 10%;
  height: 230px;
  background-color: var(--primary);
}

.spin-image {
  max-width: 200px;
}

@keyframes spin {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-33.58%, 0, 0);
  }
}

@media (max-width: 992px) {
  .spin-image {
    max-width: 150px;
  }
  .spin-modal-body {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
  }
}
